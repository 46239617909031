import React from "react";
import c from "./blogCard.module.css";
import moment from "moment";
import Badge from "@material-ui/core/Badge";

const BlogCard = (props) => {
	const ToText = (node) => {
    let tag = document.createElement("div");
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
	}

  return (
    <div className={`${c.grid_width} ${c.grid}`}>
      <div className={c.cardsmall}>
        <a
          href={props.link}
          rel="noopener noreferrer"
          target="_blank"
        >
        <div
          className={c.cardpost__image}
          style={{ backgroundImage: `url(${props.thumbnail})` }}
        >

          <div className={c.authorimg}>
            <a
              href={props.profileLink}
              rel="noopener noreferrer"
              target="_blank"
              style={{ backgroundImage: `url(${props.avatar})` }}
            >
              
            </a>
          </div>
        </div>
        </a>

        <div className={c.card_body}>
          <div className={c.author_container}>
            <span style={{paddingRight : "5px"}}>
            <a
              href={props.profileLink}
              rel="noopener noreferrer"
              target="_blank"
              style={{ backgroundImage: `url(${props.avatar})`, textDecoration : "none", color : "inherit" }}
            >
              {props.author}
            </a>
            </span>
            <div className={c.circle_dot}></div>
            <span style={{paddingLeft : "5px"}}>
              {moment(props.pubDate).format("MMM DD, YYYY")}
            </span>
          </div>

          <h5 style={{fontSize : "15px"}}>
            {
              props.title.length < 70 ? 
              props.title : 
              `${props.title.substring(0, 69)}...`
            }
          </h5>

          <p className={c.cardText}>
            {`${ToText(
              props.description.substring(0, 1000)
            )}`}
          </p>

          <div className={c.category_container}>
            {
              props.categories.map((category) => (
                <span className={c.category_data}>
                  {category}
                </span>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;