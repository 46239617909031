import { Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useStyles } from "./style"
import BlogCard from "./blogCard"
import Axios from "axios"

const BlogFeed = () => {
  const classes = useStyles()
  const mediumUrl =
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40bursery"
  const [userState, setUserState] = useState({
    profile: {
      ptitle: "",
      name: "",
      avatar: "",
      profileLink: "",
    },
    posts: [],
    isloading: false,
    error: null,
  })

  useEffect(() => {
    ;(async function fetchPosts() {
      const res = await Axios.get(mediumUrl)
      console.log("The medium url data is: ", res.data)

      const avatar = res.data.feed.image
      const profileLink = res.data.feed.link
      const response = res.data.items //This is an array with the content. No feed, no info about author etc..
      const posts = response
      const ptitle = res.data.feed.title

      setUserState({
        ...userState,
        profile: { ...userState.profile, ptitle, avatar, profileLink },
        posts,
      })

      console.log(
        "The new data is: ",
        avatar,
        profileLink,
        response,
        posts,
        ptitle,
        userState
      )
    })()
  }, [])

  return (
    <Box
      position="relative"
      paddingTop="7rem"
      paddingBottom="3rem"
      className={classes.root}
    >
      <Box className={classes.container}>
        <Typography variant="h3" className={classes.welcomeText}>
          Bursery Blog Page
        </Typography>
        <Typography variant="h6" className={classes.welcomeText2}>
          The latest educational news and information about Bursery in one spot.
        </Typography>
        <Box className={classes.row}>
          {userState.posts.map((post, index) => (
            <BlogCard key={index} {...post} {...userState.profile} {...index} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default BlogFeed
