import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
  },
	container: {
		minHeight : '70vh',
		textAlign : 'center',
		paddingTop : '10px',
		display : "flex",
		justifyContent : "center",
		alignItems : "center",
		flexDirection : "column"
	},
	row: {
		display: "flex",
    flexWrap: "wrap",
    marginRight: "20px",
    marginLeft: "20px",
	},
	welcomeText: {
		marginTop : "10px",
		marginBottom : "10px",
		maxWidth : "70%",
	},
	welcomeText2: {
		marginBottom : "30px",
		fontWeight : "400"
	}
}))